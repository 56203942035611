export const academyDataArray = [
    {
      academy_id: "1",
      academy_name: "Midterm Academy 1",
      paper_a_start_date: "2024-08-01T09:00",
      paper_a_end_date: "2024-08-01T12:00",
      paper_a_period: 150,
      paper_b_start_date: "2024-08-02T09:00",
      paper_b_end_date: "2024-08-02T12:00",
      paper_b_period: 130,
    },
    {
      academy_id: "2",
      academy_name: "Midterm Academy 2",
      paper_a_start_date: "2024-08-03T09:00",
      paper_a_end_date: "2024-08-03T12:00",
      paper_a_period: 140,
      paper_b_start_date: "2024-08-04T09:00",
      paper_b_end_date: "2024-08-04T12:00",
      paper_b_period: 120,
    },
    {
      academy_id: "3",
      academy_name: "Final Academy 1",
      paper_a_start_date: "2024-08-05T09:00",
      paper_a_end_date: "2024-08-05T12:00",
      paper_a_period: 160,
      paper_b_start_date: "2024-08-06T09:00",
      paper_b_end_date: "2024-08-06T12:00",
      paper_b_period: 140,
    },
    {
      academy_id: "4",
      academy_name: "Final Academy 2",
      paper_a_start_date: "2024-08-07T09:00",
      paper_a_end_date: "2024-08-07T12:00",
      paper_a_period: 130,
      paper_b_start_date: "2024-08-08T09:00",
      paper_b_end_date: "2024-08-08T12:00",
      paper_b_period: 150,
    },
    {
      academy_id: "5",
      academy_name: "Midterm Academy 3",
      paper_a_start_date: "2024-08-09T09:00",
      paper_a_end_date: "2024-08-09T12:00",
      paper_a_period: 140,
      paper_b_start_date: "2024-08-10T09:00",
      paper_b_end_date: "2024-08-10T12:00",
      paper_b_period: 130,
    },
    {
      academy_id: "6",
      academy_name: "Midterm Academy 4",
      paper_a_start_date: "2024-08-11T09:00",
      paper_a_end_date: "2024-08-11T12:00",
      paper_a_period: 120,
      paper_b_start_date: "2024-08-12T09:00",
      paper_b_end_date: "2024-08-12T12:00",
      paper_b_period: 140,
    },
    {
      academy_id: "7",
      academy_name: "Final Academy 3",
      paper_a_start_date: "2024-08-13T09:00",
      paper_a_end_date: "2024-08-13T12:00",
      paper_a_period: 150,
      paper_b_start_date: "2024-08-14T09:00",
      paper_b_end_date: "2024-08-14T12:00",
      paper_b_period: 160,
    },
    {
      academy_id: "8",
      academy_name: "Final Academy 4",
      paper_a_start_date: "2024-08-15T09:00",
      paper_a_end_date: "2024-08-15T12:00",
      paper_a_period: 140,
      paper_b_start_date: "2024-08-16T09:00",
      paper_b_end_date: "2024-08-16T12:00",
      paper_b_period: 130,
    },
    {
      academy_id: "9",
      academy_name: "Midterm Academy 5",
      paper_a_start_date: "2024-08-17T09:00",
      paper_a_end_date: "2024-08-17T12:00",
      paper_a_period: 160,
      paper_b_start_date: "2024-08-18T09:00",
      paper_b_end_date: "2024-08-18T12:00",
      paper_b_period: 150,
    },
    {
      academy_id: "10",
      academy_name: "Final Academy 5",
      paper_a_start_date: "2024-08-19T09:00",
      paper_a_end_date: "2024-08-19T12:00",
      paper_a_period: 130,
      paper_b_start_date: "2024-08-20T09:00",
      paper_b_end_date: "2024-08-20T12:00",
      paper_b_period: 140,
    },
  ];
  