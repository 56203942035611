import React, { useState } from "react";
import "./style.css";
import { DoubleBorderSpinner } from "../../components/loaders";
import { Axios } from "../../components/Axios";
import toast from "react-hot-toast";
const Login = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios({ url: "academy/users/login_2.php", method: "POST", data: userData })
      .then((res) => {
        console.log(res);
        if (res?.status == "success") {
          toast.success("Successfully Logged In");
          localStorage.setItem("emtyazacademy", res?.message);
          window.location.href = "/";
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => err)
      .finally(() => setLoading(false));
  };
  return (
    <div class="formcontainer">
      <div class="formcard">
        <h2>Log In</h2>
        <form onSubmit={(e) => (loading ? e.preventDefault() : signIn(e))}>
          <label htmlFor="username">Email</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            onChange={(e) =>
              setUserData({ ...userData, user_email: e.target.value })
            }
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />

          {loading ? (
            <DoubleBorderSpinner />
          ) : (
            <button type="submit">Login</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
