import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { categorizedLinks } from "../../data";
import UseGeneral from "../../customHooks/useGeneral";

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { userData } = UseGeneral();

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const linksData = categorizedLinks(userData?.data?.email);

  return (
    <aside className={`sidebar ${isOpen ? "" : "closed"}`}>
      <div className="logoCon">
        {/* <h4>
          <img src={"https://emtyazedu.com/static/media/logo.9ed77f678984e0df496d.png"} style={{width:"115px"}} alt="" />
        </h4> */}
        <span
          onClick={toggleNav}
          className={`menu ${isOpen ? "open" : "close"}`}
        >
          <i className="fas fa-bars"></i>
        </span>
      </div>
      <ul>
        {linksData.map((category, index) => (
          <React.Fragment key={index}>
            <li className="category-header">{category.category_name}</li>
            {category.links.map((link, i) => {
            return  <li key={i}>
                <NavLink to={link.to}>
                  <i className={link.icon}></i>
                  <span> {link.labelEnglish}</span>
                </NavLink>
              </li>;
            })}
          </React.Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default SideNav;
