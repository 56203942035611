import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { BASE_URL } from "../../../components/Axios/base_uri";
import PopUp from "../../../components/popup/index";
import * as XLSX from "xlsx";
import "./style.css";

const UploadAcademyExcel = ({ showAddCatModal, setShowAddCatModal }) => {
  const navigate = useNavigate();
  const [academyData, setAcademyData] = useState({});
  const [fileInfo, setFileInfo] = useState(null); // State to store file info

  const handleSetAcademyData = (e) => {
    const { name, value } = e.target;
    setAcademyData({
      ...academyData,
      [name]: value,
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileType = file.name.split('.').pop().toLowerCase();

    // Check if the uploaded file is an Excel file
    if (fileType !== "xlsx" && fileType !== "xls") {
      toast.error("Please upload a valid Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Get the number of rows in the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const numberOfRows = jsonData.length;

      // Calculate file size in MB
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      // Set file information in state
      setFileInfo({
        name: file.name,
        size: fileSizeMB,
        rows: numberOfRows,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const addAcademy = async (e) => {
    try {
      e.preventDefault();
      // Prepare and send the academy data to the backend
      const addProgress = await axios.post(BASE_URL + "academy/add_academy", academyData);
      console.log(addProgress);
    } catch (err) {
      toast.error("Failed to upload academy.");
    }
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding cosmic-23w">
        <PopUp
          open={showAddCatModal}
          setOpen={setShowAddCatModal}
          title={"Add Academy"}
          children={
            <form
              className="cosmic-form-56g"
              style={{ overflowY: "auto" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!academyData?.loading) addAcademy(e);
              }}
            >
              <div className="field_input cosmic-input-92d">
                <label htmlFor="">Academy File</label>
                <input
                  type="file"
                  name="file_attachment"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload} // Handle file upload
                />
              </div>

              {fileInfo && (
                <div className="cosmic-table-wrapper-47l">
                  <h4>File Information:</h4>
                  <table className="cosmic-table-89k">
                    <tbody>
                      <tr>
                        <td className="cosmic-label-03q"><strong>File Name:</strong></td>
                        <td className="cosmic-data-54x">{fileInfo.name}</td>
                      </tr>
                      <tr>
                        <td className="cosmic-label-03q"><strong>File Size:</strong></td>
                        <td className="cosmic-data-54x">{fileInfo.size} MB</td>
                      </tr>
                      <tr>
                        <td className="cosmic-label-03q"><strong>Number of Rows:</strong></td>
                        <td className="cosmic-data-54x">{fileInfo.rows}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <button className="popup_agree_btn cosmic-button-29z">
                {academyData?.loading ? <Loader /> : "Add"}
              </button>
            </form>
          }
        />
      </div>
    </>
  );
};

export default UploadAcademyExcel;
