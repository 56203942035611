import React from "react";
import SideNav from "../sideNav";
import "./style.css";
import DashboardHeader from "../header";
import Welcome from "../../components/welcome";
import Footer from "../footer";
const DefaultLayout = ({ children }) => {
  return (
    <div className="defaultLayout">
      <SideNav />
      <div className="siteContent">
        {" "}
        <DashboardHeader />
        <div className="childs">
          <div>
            <Welcome />
          </div>
          <div style={{minHeight:"78vh"}}>{children}</div> 
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
