import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { BASE_URL } from "../../../components/Axios/base_uri";
import PopUp from "../../../components/popup/index";
import "./style.css";

const AddCards = ({ getData, showAddCardModal, setShowAddCardModal }) => {
  const navigate = useNavigate();
  const [cardCount, setCardCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSetCardCount = (e) => {
    setCardCount(e.target.value);
  };

  const addCards = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      count: cardCount,
    };

    window.open(
      BASE_URL +
        "create_cards.php?academy_id=" +
        showAddCardModal?.academy_id +
        "&count=" +
        payload?.count,
      "_blanck"
    );
    setLoading(false);
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <PopUp
          open={showAddCardModal}
          setOpen={setShowAddCardModal}
          title={"Add Cards"}
          children={
            <form
              style={{ height: "40vh", overflowY: "auto" }}
              onSubmit={addCards}
            >
              <div className="field_input">
                <label htmlFor="card_count">Number of Cards</label>
                <input
                  type="number"
                  name="card_count"
                  min="1"
                  value={cardCount}
                  onChange={handleSetCardCount}
                />
              </div>
              <button className="popup_agree_btn">
                {loading ? <Loader /> : "Add"}
              </button>
            </form>
          }
        />
      </div>
    </>
  );
};

export default AddCards;
