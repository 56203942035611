import axios from "axios";
import { BASE_URL } from "../components/Axios/base_uri";

export const uploadImage = async (file) => {
  const formData = new FormData();

  formData.append("image", file);
  const imageUpload = await axios.post(
    BASE_URL + "item_img_uploader.php",
    formData,
    {
      "Content-Type": "Content-Type: multipart/form-data",
    }
  );
  return imageUpload?.data?.message;
};
