export const pagesName = [
  {
    name_en: "Academy",
    name_ar: "الامتحانات",
    route: "/academy",
    
  },
  {
      name_en: "Home",
      name_ar: "الرئيسية",
      route: "/",
  },
  {
      name_en: "Countries",
      name_ar: "الدول",
      route: "/Countries",
  },
  {
    name_en: "languages",
    name_ar: "الدول",
    route: "/languages",
},
  ];
  