import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "rsuite/Loader/styles/index.css";
import "./App.css";
import "./components/loaders/style.css";
import UseGeneral from "./customHooks/useGeneral";
import DefaultLayout from "./layouts/defaultLayouts";
import Academy from "./pages/Academy";
import { fetchUserData } from "./store/reducers/userReducer";
import Home from "./pages/home";
import Login from "./pages/login";

function App() {
  const dispatch = useDispatch();

  const { userData } = UseGeneral();
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  return (
    <>
      {!localStorage.getItem("emtyazacademy") ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <DefaultLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Academy />} />
          </Routes>
        </DefaultLayout>
      )}
      {/* )}  */}

      <div className="toaster" style={{ zIndex: 131232131413141 }}>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              zIndex: "9999999999999999999999",
            },
          }}
        />
      </div>
    </>
  );
}

export default App;
