export const categorizedLinks = (email) => {
  return [
    {
      id: 1,
      category_name: "Dashboard",
      links: [
        {
          to: "/",
          icon: "fas fa-tachometer-alt",
          labelArabic: "الرئيسية",
          labelEnglish: "Home",
          showcussup: false,
          showcusstore: false,
          showcusdel: false,
        },
      ],
    },
    {
      id: 2,
      category_name: "Academy",
      links: [
        {
          to: "/academy",
          icon: "fas fa-file-alt",
          labelArabic: "الأكاديمية",
          labelEnglish: "Academy",
          showcussup: false,
          showcusstore: false,
          showcusdel: false,
        },
      ],
    },
  ];
};
