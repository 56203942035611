// Footer.js
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-bottom">
      <p>&copy; All Rights Reserved to Groot in IB {new Date().getFullYear()}</p>
      <p>
        Made With
          <span role="img" aria-label="heart">
            ❤️
          </span>{" "}
          By Camp Coding
        </p>
      </div>
    </footer>
  );
};

export default Footer;
