import { useSelector } from "react-redux";

const UseGeneral = () => {
  const user = useSelector((state) => state?.user);
  const logOut = () => {
    localStorage?.clear();
    window.location.reload();
  };

  return {
    userData: user,
    logOut: logOut,
  };
};

export default UseGeneral;
