import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { BASE_URL } from "../../../components/Axios/base_uri";
import PopUp from "../../../components/popup/index";
import "./style.css";
import { uploadImage } from "../../../utils";

const AddAcademy = ({ getData, showAddCatModal, setShowAddCatModal }) => {
  const navigate = useNavigate();
  const [academyData, setAcademyData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSetAcademyData = (e) => {
    const { name, value } = e.target;
    setAcademyData({
      ...academyData,
      [name]: value,
    });
  };

  const addAcademy = async (e) => {
    e.preventDefault();
    setLoading(true);
    let image = null;
    if (academyData?.academy_image_file) {
      image = await uploadImage(academyData?.academy_image_file);
    }
    const payload = {
      academy_name: academyData.academy_name,

      user_name: academyData.user_name,
      academy_image: image,
      academy_code: academyData.academy_code,
      academy_admin_email: academyData.academy_admin_email,
      academy_admin_password: academyData.academy_admin_password,
      allPermissions:
        "U2FsdGVkX19ZhIFn3IloWMePy6PVRtErTaoFvjdsQ/1XIBOk+tgv0IIecO3gD9isVc1uROCJGMQih6RCSwK7YIq+l7IgC8lhb5G2gO8Az2iGbjx/pVLdivtKQgoPD8EmwETPllbzv1nvJWqpcytVe58M4hwvwut7rGqj/NmX52BIvcpTTTk9VcCk3Mi/Gt0guS+IgGSVgALmmQuIiufEYjY3DAeE9qtDyDsH7SXpV+fwCSbiZNixpxHn9n15eTasxjSubMM7CfvRCeLrNd3O20GLLFcAllUO4VR3BZAlTxjds4rmc3PxZPjK6vF3b0vieach8xCmdCd3zavMBsLpVOXOTkLy2V3WWKKzhctoZ822ykjwPx+8G9W+nONQkob4vSBA1eCWTOB+X7sGAiQrRwdpFchvdeMEuEbIN96/MIm8HJ3Nwh6sbh/jfUOceOFz1D2BNHY2eijD1536IIWzMua6ffVlW/Ked7yk/1jnhvFkAX/Urase9m2v4iUZi8dNW/As6ZJg2aO+cpKq2sURIz+3AaEqlADfzooQ+7U6H1Gc1FluXJnyuruhHqP08ZLIS0ixsd95n74p4GnYAV7eVlqKqG8ZfW+7Hv8aE54wtqFQZ+D7DKAfE+3QyUd/1RAnGvZ1vJ8q44rjUE2PCgNDxw==",
    };

    try {
      const response = await axios.post(
        BASE_URL + "academy/create_academy.php",
        payload
      );
      if (response.data.status === "success") {
        toast.success("Academy created successfully!");
        getData();
        setShowAddCatModal(false);
      } else {
        toast.error(response.data.message || "Failed to create academy.");
      }
    } catch (err) {
      toast.error("An error occurred while creating the academy.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <PopUp
          open={showAddCatModal}
          setOpen={setShowAddCatModal}
          title={"Add Academy"}
          children={
            <form
              style={{ height: "80vh", overflowY: "auto" }}
              onSubmit={addAcademy}
            >
              <div className="field_input">
                <label htmlFor="academy_name">Academy Name</label>
                <input
                  type="text"
                  name="academy_name"
                  onChange={handleSetAcademyData}
                />
              </div>
              <div className="field_input">
                <label htmlFor="academy_code">Academy Code</label>
                <input
                  type="text"
                  name="academy_code"
                  onChange={handleSetAcademyData}
                />
              </div>
              <div className="field_input">
                <label htmlFor="academy_admin_email">Academy Admin Email</label>
                <input
                  type="text"
                  name="academy_admin_email"
                  onChange={handleSetAcademyData}
                />
              </div>{" "}
              <div className="field_input">
                <label htmlFor="academy_admin_password">
                  Academy Admin Password
                </label>
                <input
                  type="text"
                  name="academy_admin_password"
                  onChange={handleSetAcademyData}
                />
              </div>{" "}
             <div className="field_input">
                <label htmlFor="user_name">
                  Academy Admin user name
                </label>
                <input
                  type="text"
                  name="user_name"
                  onChange={handleSetAcademyData}
                />
              </div>{" "}  <div className="field_input">
                <label htmlFor="academy_image_file">Academy Image</label>
                <input
                  type="file"
                  name="academy_image_file"
                  onChange={(e) => {
                    setAcademyData({
                      ...academyData,
                      academy_image_file: e.target.files[0],
                    });
                  }}
                />
              </div>
              <div className="academy_image">
                {academyData?.academy_image_file ? (
                  <img
                    width={200}
                    src={URL.createObjectURL(academyData?.academy_image_file)}
                    alt=""
                  />
                ) : null}
              </div>
              <button className="popup_agree_btn">
                {loading ? <Loader /> : "Add"}
              </button>
            </form>
          }
        />
      </div>
    </>
  );
};

export default AddAcademy;
