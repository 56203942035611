import React from 'react';
import Statistics from '../../layouts/home/states';
import "./style.css";

const Home = () => {
 

  return (
    <div className="columnDiv">
      {/* <Statistics /> */}
      <h1>Welcome To Academy System...!</h1>
    </div>
  );
};

export default Home;
