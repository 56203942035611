import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { add, edit } from "../../assets/svgIcons";
import TableLayout from "../../components/table";
import AddAcademy from "./management/add";
import "./style.css";
import UploadAcademyExcel from "./management/uploadExel";
import { academyDataArray } from "./staticData";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import UpdateAcademy from "./management/UpdateAcademy";
import AddCards from "./management/create_cards";

const Solved = () => {
  const navigate = useNavigate();
  const [academy, setAcademy] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(null);
  const [showAddCardModal, setShowAddCardModal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showUploadExcelModal, setShowUploadExcelModal] = useState(null);
  const academyHeader = [
    {
      label: "#",
      dataIndex: "academy_id",
    },
    {
      label: "Academy Name",
      dataIndex: "academy_name",
    },
    {
      label: "Academy Code",
      dataIndex: "academy_code",
    },
    {
      label: "Academy Admin Email",
      dataIndex: "academy_admin_email",
    },
    {
      label: "Academy Admin Password",
      dataIndex: "academy_admin_password",
    },
    {
      label: "academy_image",
      dataIndex: "academy_image",
      type: "children",
      children: ({ row }) => {
        return (
          <img
            height={100}
            style={{ objectFit: "contain" }}
            src={row?.academy_image}
            alt=""
          />
        );
      },
    },
    {
      label: "Edit",
      dataIndex: "Edit",
      type: "children",
      children: ({ row }) => {
        return (
          <button
            className="btn btn-success"
            onClick={() => setShowEditModal(row)}
          >
            Edit
          </button>
        );
      },
    },
  ];
  const [data, setData] = useState(null);
  const getData = async () => {
    try {
      const data = await axios.get(BASE_URL + "academy/select_academy.php");
      console.log(data);
      setData(data?.data?.message);
    } catch (err) {
      setData([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="orders-container">
            <div className="orders-header">
              <div className="search-container">
                <i className="fas fa-search search-icon"></i>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search..."
                  onChange={(e) => null}
                />
              </div>
              <div className="orders-actions">
                <div onClick={() => setShowAddCatModal(true)}>{add}</div>
              </div>
            </div>
          </div>

          {!academyDataArray ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={academyHeader} data={data} />
          )}
        </div>
      </div>
      <AddAcademy
        showAddCatModal={showAddCatModal}
        setShowAddCatModal={setShowAddCatModal}
        getData={getData}
      />
      <AddCards
        showAddCardModal={showAddCardModal}
        setShowAddCardModal={setShowAddCardModal}
        getData={getData}
      />{" "}
      <UpdateAcademy
        academyDataToUpdate={showEditModal}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        getData={getData}
      />
      <UploadAcademyExcel
        showAddCatModal={showUploadExcelModal}
        setShowAddCatModal={setShowUploadExcelModal}
      />
    </>
  );
};

export default Solved;
