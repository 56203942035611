import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css"; // Import CSS file for styling
import UseGeneral from "../../customHooks/useGeneral";
import { pagesName } from "./pagesName"; // Import your pagesName array

function DashboardHeader() {
  const { userData, logOut } = UseGeneral();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation(); // Get the current location

  // Toggle dark/light mode
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle("dark-mode", !isDarkMode);
  };

  // Personalized greeting based on time
  const getGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) return "Good morning";
    if (hours < 18) return "Good evening";
    return "Good Evening";
  };

  // Get the current page name based on the pathname using includes
  const currentPage = pagesName.find((page) =>
    location.pathname.includes(page.route)
  );
  const currentPageName = currentPage ? currentPage.name_en : "Home";

  return (
    <div className={`dashboard-header ${isDarkMode ? "dark" : ""}`}>
      <div className="right_header_part">
        <h1>{currentPageName}</h1>
      </div>
      <div className="left_header_part">
        <div className="header-logo">
          <input type="text" placeholder="Search..." />
          <i className="fas fa-search search-icon"></i>
        </div>

        <div className="header-icons">
          <div className="dropdown profile">
            <span className="profile-name">{userData?.data?.name}</span>
            <div className="dropdown-content">
              <ul>
                <li
                  className="btn btn-primary"
                  style={{ color: "white" }}
                  onClick={() => logOut()}
                >
                 LogOut
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button className="theme-toggle" onClick={toggleTheme}>
          <i className={`fas ${isDarkMode ? "fa-sun" : "fa-moon"}`}></i>
        </button>

        <div className="greeting">
          <h4>
            {getGreeting()}, {userData?.data?.name}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
